"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveCard = exports.removeCard = exports.addCard = exports.locations = void 0;
const Util_1 = require("utils/Util");
exports.locations = {
    pack: 'pack',
    deck: 'deck',
    sideboard: 'sideboard',
};
class DraftLocation {
    constructor(type, row, col, index) {
        this.type = type;
        this.row = row;
        this.col = col;
        this.index = index;
    }
    static pack(index) {
        return new DraftLocation('pack', -1, -1, index);
    }
    static picks(row, col, index) {
        return new DraftLocation('picks', row, col, index);
    }
    static deck(row, col, index) {
        return new DraftLocation('deck', row, col, index);
    }
    static sideboard(row, col, index) {
        return new DraftLocation('sideboard', row, col, index);
    }
    equals(other) {
        if (this.type !== other.type) {
            return false;
        }
        if (this.type === 'pack') {
            return this.index === other.index;
        }
        return (0, Util_1.arraysEqual)([this.row, this.col, this.index], [other.row, other.col, other.index]);
    }
    toString() {
        if (this.type === 'pack') {
            return `DraftLocation.${this.type}(${this.index})`;
        }
        return `DraftLocation.${this.type}(${this.row}, ${this.col}, ${this.index})`;
    }
}
const addCard = (cards, target, card) => {
    const newCards = [...cards];
    if (newCards[target.row].length < 1 + target.col) {
        newCards[target.row] = newCards[target.row].concat(new Array(1 + target.col - newCards[target.row].length).fill([]));
    }
    newCards[target.row] = [...newCards[target.row]];
    newCards[target.row][target.col] = [...newCards[target.row][target.col]];
    newCards[target.row][target.col].splice(target.index, 0, card);
    return newCards;
};
exports.addCard = addCard;
const removeCard = (cards, source) => {
    const newCards = [...cards];
    newCards[source.row] = [...newCards[source.row]];
    newCards[source.row][source.col] = [...newCards[source.row][source.col]];
    const [card] = newCards[source.row][source.col].splice(source.index, 1);
    return [card, newCards];
};
exports.removeCard = removeCard;
const moveCard = (cards, source, target) => {
    const newCards = [...cards];
    if (source.type !== target.type) {
        throw new Error('Cannot move card between different locations');
    }
    if (source.row === target.row && source.col === target.col) {
        // moving within the same stack
        if (source.index === target.index) {
            return newCards;
        }
        if (source.index > target.index) {
            // moving up, so we need to remove the card first, then adjust it for the new index
            const [card, newSource] = (0, exports.removeCard)(newCards, source);
            const newTarget = new DraftLocation(target.type, target.row, target.col, target.index - 1);
            return (0, exports.addCard)(newSource, newTarget, card);
        }
    }
    // moving between stacks
    const [card, newSource] = (0, exports.removeCard)(newCards, source);
    return (0, exports.addCard)(newSource, target, card);
};
exports.moveCard = moveCard;
exports.default = DraftLocation;
