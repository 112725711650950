"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const parser_1 = require("markdown/parser");
const Util_1 = require("utils/Util");
const CSRFContext_1 = require("../contexts/CSRFContext");
const useComments = (parent, type) => {
    const { csrfFetch } = (0, react_1.useContext)(CSRFContext_1.CSRFContext);
    const [comments, setComments] = (0, react_1.useState)([]);
    const [loading, setLoading] = (0, react_1.useState)(true);
    const [lastKey, setLastKey] = (0, react_1.useState)(null);
    const addComment = (0, react_1.useCallback)(async (comment) => {
        const mentions = (0, parser_1.findUserLinks)(comment).join(';');
        const response = await csrfFetch(`/comment/addcomment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                body: comment,
                mentions,
                parent,
                type,
            }),
        });
        if (!response.ok) {
            console.error('Failed to add comment');
            console.log(response);
            const json = await response.json();
            console.log(json);
            return;
        }
        const val = await response.json();
        setComments([val.comment, ...comments]);
    }, [comments, parent, type]);
    const editComment = (0, react_1.useCallback)(async (editRequest) => {
        await csrfFetch(`/comment/edit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                comment: editRequest,
            }),
        });
        const clone = JSON.parse(JSON.stringify(comments));
        for (let i = 0; i < clone.length; i++) {
            if (clone[i].id === editRequest.id) {
                if (editRequest.remove) {
                    clone[i].body = '[deleted]';
                    clone[i].owner = {
                        id: '404',
                        username: 'Anonymous',
                    };
                    clone[i].image = {
                        uri: 'https://c1.scryfall.com/file/scryfall-cards/art_crop/front/0/e/0e386888-57f5-4eb6-88e8-5679bb8eb290.jpg?1608910517',
                        artist: 'Allan Pollack',
                        id: '0c082aa8-bf7f-47f2-baf8-43ad253fd7d7',
                        imageName: 'Ambush Viper',
                    };
                }
                else if (editRequest.content !== undefined) {
                    clone[i].body = editRequest.content;
                }
            }
        }
        setComments(clone);
    }, [comments]);
    (0, react_1.useEffect)(() => {
        const getData = async () => {
            const response = await csrfFetch(`/comment/getcomments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    parent,
                }),
            });
            const result = await response.json();
            setComments(result.comments);
            setLastKey(result.lastKey);
            setLoading(false);
        };
        getData();
    }, [parent]);
    const getMore = (0, react_1.useCallback)(async () => {
        setLoading(true);
        // intentionally wait to avoid too many DB queries
        await (0, Util_1.wait)(2000);
        const response = await csrfFetch(`/comment/getcomments`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                parent,
                lastKey,
            }),
        });
        if (response.ok) {
            const json = await response.json();
            if (json.success === 'true') {
                setComments([...comments, ...json.comments]);
                setLastKey(json.lastKey);
                setLoading(false);
            }
        }
    }, [parent, lastKey, comments]);
    return [comments, addComment, loading, editComment, lastKey, getMore];
};
exports.default = useComments;
